<template>
    <div v-if="showCard">
        <!--AxZPusPurdmDetail.vue-->
        <el-container>
            <el-main style="height: 100%;">
                <el-row>
                    <!-- <vxe-form-item :span="7" title="账套:" field="name" :item-render="{}">
                        <vxe-radio-group v-model="checkacc" >
                            <vxe-radio :label="item"  v-for="(item) in checkList" :key="(item.account)" :content="item.name" ></vxe-radio>
                        </vxe-radio-group>
                    </vxe-form-item> -->
                   
                    <el-col :span="20">
                        <el-button-group>
                            <el-button type="" size="mini" @click="sysdetailadd">新增</el-button>
                            <!-- <el-button type="" :visable="false" size="mini" @click="sysdetailupdate">修改</el-button> -->
                            <el-button type="" size="mini" @click="sysdetailsave">保存</el-button>
                            <!-- <p>
                                <vxe-radio-group v-model="value3">
                                    <vxe-radio label="1" content="HTML"></vxe-radio>
                                    <vxe-radio label="2" content="CSS"></vxe-radio>
                                    <vxe-radio label="3" content="Javascript"></vxe-radio>
                                    <vxe-radio label="4" content="SASS"></vxe-radio>
                                    <vxe-radio label="5" content="LESS"></vxe-radio>
                                </vxe-radio-group>
                            </p> -->
                            
                            
                        </el-button-group>
                        
                    </el-col>
                </el-row>
                <vxe-form title-align="right" title-width="140">
                    <vxe-form-gather span="24" :visible="false" >
                        <vxe-form-item title="guid" field="guid" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].guid" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].guid" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                     <vxe-form-gather span="24" :visible="true" >
                        <vxe-form-item  title="账套:" field="name" :item-render="{}" span="24">

                               <vxe-radio-group v-model="checkacc" >
                                    <vxe-radio :label="item.account"  v-for="(item) in checkList" :key="(item.account)" :content="item.name" ></vxe-radio>
                                    <!-- <vxe-radio disabled :label="item.account"  v-for="(item) in checkList" :key="(item.account)" :content="item.name" ></vxe-radio> -->
                                </vxe-radio-group>
                             <!-- <template #default>
                                <vxe-input type="date" v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].voucherdate" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input type="date" v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].voucherdate" placeholder="" size="mini" clearable></vxe-input>
                            </template> -->
                            <!-- <vxe-checkbox-group v-model="checkList1" >
                                <vxe-checkbox :label="item"  v-for="(item,index) in checkList" :key="(item.account)" :content="item.name" ></vxe-checkbox>
                            </vxe-checkbox-group> -->
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        
                        <vxe-form-item title="单据日期" field="voucherdate" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input type="date" v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].voucherdate" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input type="date" v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].voucherdate" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    
                        <vxe-form-item title="单据编号" field="externalcode" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].externalcode" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].externalcode" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                  
                        <vxe-form-item title="业务类型"  field="businesstype" :item-render="{}" span="6">
                            <template #default>
                                 <vxe-select v-model="DataSet.tables[mastertable].rowsview[0].businesstype" placeholder="" size="mini">
                                    <vxe-option v-for="type in selectstatus" :key="type.id" :value="type.id" :label="type.name">
                                    </vxe-option>
                                </vxe-select>
                                <!-- <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].businesstype" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].businesstype" placeholder="" size="mini" clearable></vxe-input> -->
                            </template>
                        </vxe-form-item>
                   
                        <vxe-form-item title="供应商信息" field="partnercode" :item-render="{}" span="6">
                            <template #default>
                                <!-- <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].partnercode" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].partnercode" placeholder="" size="mini" clearable></vxe-input> -->
                                <el-select v-model="DataSet.tables[mastertable].rowsview[0].partnercode" size="mini" filterable placeholder="请选择">
                                    <el-option v-for="item in select.partnercode" :key="item.code" :label="item.name" :value="item.code">
                                    </el-option>
                                </el-select>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="票据类型" field="invoicetype" :item-render="{}" span="6">
                            <template #default>
                                  <vxe-select v-model="DataSet.tables[mastertable].rowsview[0].invoicetype" placeholder="" size="mini">
                                    <vxe-option v-for="type in selectstatus1" :key="type.id" :value="type.id" :label="type.name">
                                    </vxe-option>
                                </vxe-select>
                                <!-- <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].invoicetype" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].invoicetype" placeholder="" size="mini" clearable></vxe-input> -->
                            </template>
                        </vxe-form-item>
                        <vxe-form-item title="备注" field="memo" :item-render="{}" span="12">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].memo" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].memo" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                </vxe-form>
                <el-tabs type="border-card" size="mini" v-model="currenttable">
                    <el-tab-pane label="订购单明细" name="ax_PU_PurchaseOrder_b">
                        <el-container>
                            <el-main>
                                <el-button-group>
                                    <el-button type="" size="mini" @click="sysdetaildtadd('ax_PU_PurchaseOrder_b')">增加明细</el-button>
                                    <el-button type="" size="mini" @click="sysdetaildtdel('ax_PU_PurchaseOrder_b')">删除明细</el-button>
                                </el-button-group>
                                <vxe-table  ref="ax_PU_PurchaseOrder_b" @current-change="sysdetailcurrentchange" :cell-style="rowStyle" show-overflow highlight-current-row border  highlight-hover-row size="mini" 
                                    :data="DataSet.tables['ax_PU_PurchaseOrder_b'].rowsview" :height="meta.h" :width="2000">
                                 
                                    <vxe-table-column field="guid" title="guid" :visible ="false"  width="180"></vxe-table-column>
                                    <vxe-table-column field="pguid" title="pguid" :visible ="false" width="180"></vxe-table-column>
                
                                    <vxe-table-column field="inventorycode" title="存货编号" width="150">
                                         <template #default="{row, rowIndex }">
                                            <vxe-input v-if="content.inventorycode !='view'" v-model="row.inventorycode"></vxe-input>
                                            <vxe-input v-else disabled v-model="row.inventorycode"></vxe-input>
                                         </template>

                                    </vxe-table-column>
                                    <vxe-table-column field="projectcode" title="项目信息" width="250">
                                         <template #default="{row, rowIndex }">
                                            <vxe-input v-if="content.projectcode !='view'" v-model="row.projectcode"></vxe-input>
                                            <vxe-input v-else disabled v-model="row.projectcode"></vxe-input>
                                         </template>
                                    </vxe-table-column>
                                    <vxe-table-column field="Quantity" title="数量" width="100">
                                         <template #default="{row, rowIndex }">
                                            <vxe-input v-if="content.Quantity !='view'" v-model="row.Quantity"></vxe-input>
                                            <vxe-input v-else disabled v-model="row.Quantity"></vxe-input>
                                         </template>
                                    </vxe-table-column>
                                    <vxe-table-column field="unitcode" title="单位" width="100">
                                         <template #default="{row, rowIndex }">
                                            <vxe-input v-if="content.unitcode !='view'" v-model="row.unitcode"></vxe-input>
                                            <vxe-input v-else disabled v-model="row.unitcode"></vxe-input>
                                         </template>
                                    </vxe-table-column>
                                    <vxe-table-column field="price" title="单价" width="100">
                                         <template #default="{row, rowIndex }">
                                            <vxe-input v-if="content.unitcode !='view'" v-model="row.price"></vxe-input>
                                            <vxe-input v-else disabled v-model="row.price"></vxe-input>
                                         </template>
                                    </vxe-table-column>
                                </vxe-table> 
                            </el-main>
                        </el-container>
                    
                    </el-tab-pane>
            
                </el-tabs>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    export default {
        name: "ax_PU_PurchaseOrder_bdetail",
        extends: ax.control1(),
        data() {
            return {
                checkList:[],
                checkList1:[],
                 select: {
                   partnercode:[]
                },
                checkacc:"UFTData303503_000023",
                selectstatus:[{id:"01",name:"普通采购"},{id:"02",name:"采购退货"}],
                selectstatus1:[{id:"00",name:"专票"},{id:"01",name:"普票"}],
                vouchertype: "detail",
                mastertable: "ax_PU_PurchaseOrder",
                model: "syc",
                settings: "axPUPurchaseOrder",
                currenttable:"ax_PU_PurchaseOrder_b",
                datainit: {
                    "ax_PU_PurchaseOrder": {
                        "keyname": "ax_PU_PurchaseOrder",
                        "key": "guid",
                        "ptable": "",
                        "pkey": "",
                        "relation": "",
                        "datatype": "datafree",
                        "dataname": "主表",
                        "jsexps": [],
                        "hasseq": true,
                        "rows": [{
                               guid:"",
                               voucherdate:ax.base.getNowFormatDate(),
                               externalcode:"",
                               businesstype:"01",
                               partnercode:"",
                               invoicetype:"00",
                               memo:"",
                        }]
                    },
                        "ax_PU_PurchaseOrder_b": {
                        "keyname": "ax_PU_PurchaseOrder_b",
                        "key": "guid",
                        "ptable": "ax_PU_PurchaseOrder",
                        "pkey": "guid",
                        "relation": "pguid",
                        "datatype": "datafree",
                        "dataname": "主表",
                        "jsexps": [],
                        "hasseq": true,
                        "rows": [{
                                guid:"",
                               pguid:"",
                               inventorycode:"",
                               projectcode:"",
                               Quantity:0 ,
                               unitcode:"",
                               price:0 ,
                        }]
                    }
                },
                showCard:false,
                invrow:[]
            }
        },
        mounted(){
            this.meta.h = 470;
            this.meta.w = 50;
            this.select2();
            this.sysgetselect();
        },
        methods:{
             select2() {
                let _this = this
                ax.base.post("/syc/selectaccount", _this.p).then(data => {
                    console.log(data);
                    _this.checkList = data.data;
                    console.log("---------------------------------------------------")
                    console.log(_this.checkList[0].account);
                    _this.checkacc=_this.checkList[0].account;
                    _this.showCard=true;
                }).catch(data => {
                });
            },
            sysquerybyguid() {
                let row = this.content.row;
                let _this = this;
                this.content.pagestatus = 'view';
                ax.base.post("/zym/sysquerybyguid", row).then(data => {
                    _this.dataquery = data.data;
                    _this.content.pagestatus = 'view';
                    _this.load();
                })
            },
            sysgetselect() {
                let _this = this;
                ax.base.post("/zym/sysgetselect", { "type": "select" }).then(data => {
                        _this.select = data.data;
                        // _this.price = data.data.price[0].price;
                        // if (_this.content.pagestatus == 'add') {
                        //     _this.DataSet.tables['ax_vou_offer_a'].rowsview[0].price = _this.price;
                        // }
                    })
                    .catch(data => {
                        _this.$XModal.message({ message: data.data, status: 'error' })
                    })

            },
            // handleChange(file,fileList){
            //     this.fileList = fileList;

            //     // console.log(file)
            //    // this.DataSet.tables['ax_z_bas_boma'].currentrow.name=file.name;
            //     //生成本地图片的url，直接src显示,删除或移动后url就失效了
            //     // this.imgUrl = URL.createObjectURL(file.raw);
            //     // console.log(this.imgUrl)
            // },
            
            // sysdetailcurrentchange({ row, rowIndex, $rowIndex, column, columnIndex, $columnIndex, $event }) {
               
            //     this.DataSet.tables[this.currenttable].currentrowindex = rowIndex;
            // },
            // sysdetailgetsaveparams(){
            //     this.$set(this.params, "as", this.DataSet.todict()[this.mastertable].rows)
            //     this.$set(this.params,"purdd",this.DataSet.todict()['ax_z_pus_purdd'].rows)
            // },
            //  sysdetailload() {
            //     this.DataSet = ax.dataset(this.datainit);
            //     this.DataSet.init();
            //     let as = this.dataquery.as;                
            //     for (let a of as) {
            //         this.DataSet.tables[this.mastertable].viewrow(a);
            //     }

            //     this.DataSet.tables[this.mastertable].currentrowindex = 0;
            //     this.DataSet.tables[this.mastertable].valuechange = function(row, column, value, jsexps) {
            //         if (row.rowstate == "none") {
            //             row.rowstate = "modify"
            //         }
            //     }
            //     let as1 = this.dataquery.purdd;
              
            //     this.cnt=as1.length;
            //     for (let a of as1) {
            //         this.DataSet.tables['ax_z_pus_purdd'].viewrow(a);
            //     }
            //     this.DataSet.tables['ax_z_pus_purdd'].currentrowindex = 0;
            //     this.DataSet.tables['ax_z_pus_purdd'].valuechange = function(row, column, value, jsexps) {
            //         if (row.rowstate == "none") {
            //             row.rowstate = "modify"
            //         }
            //     }
            // },
           
        }
    }
</script>
<style>
     .vxe-input--inner[disabled] {
        cursor: not-allowed;
        color: black;
        background-color: #f3f3f3;
    }
    a{
        line-height:28px;    }
</style>